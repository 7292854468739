import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Link from 'next/link'
import { useEffect, useState } from 'react'

interface CookieConsentProps {
  className?: string
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ className = '' }) => {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    const v = localStorage.getItem('isConsentAccepted')
    if (v === 'true') {
      setVisible(false)
    }
  }, [])
  const onAccept = () => {
    localStorage.setItem('isConsentAccepted', 'true')
    setVisible(false)
  }
  const onDecline = () => {
    localStorage.setItem('isConsentAccepted', 'false')
    setVisible(false)
  }
  return (
    <div
      className={cn(
        className,
        'cookieConsentPoup CookieConsent items-center bg-darkgray text-clouds text-[15px] font-primary font-regular flex max-w-screen-2xl w-full fixed z-50 bottom-0 left-1/2 -translate-x-1/2 justify-between',
        { hidden: !visible },
      )}
    >
      <div className="flex flex-col md:flex-row items-center mx-5 md:ml-[33px] my-4">
        <div className="md:w-3/4 w-full">
          <div className="flex flex-row justify-between">
            <div>
              We use cookies to monitor the performance of our website, improve user experience, and assist in our
              marketing efforts. By continuing to browse our site, you agree to our use of cookies.
            </div>
          </div>
        </div>
        <div className="flex md:ml-[0px] mx-auto md:mr-[unset] md:my-0 my-4">
          <button
            className="bg-white text-darkgray font-secondary font-medium text-[13px] p-4 h-[40px] mx-1 rounded-[1.875rem] flex items-center"
            onClick={onAccept}
          >
            Okay
          </button>
          <button
            className="bg-white text-darkgray font-secondary font-medium text-[13px] p-4 h-[40px] mx-1 rounded-[1.875rem] flex items-center"
            onClick={onDecline}
          >
            No
          </button>
          <Link href="/cookie-policy">
            <button className="bg-white text-darkgray font-secondary font-medium text-[13px] p-4 h-[40px] mx-1 rounded-[1.875rem] flex items-center">
              Cookie Policy
            </button>
          </Link>
        </div>
        <IonIcon
          name="close"
          className="text-[24px] pr-[15px] cursor-pointer"
          onClick={() => {
            setVisible(false)
          }}
        />
      </div>
    </div>
  )
}
